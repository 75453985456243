import React from 'react';

function AboutUs() {
  return (
    <div>
      <div className='bg-[#E7D3B7] flex items-center justify-center p-10 '>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 w-full'>
          <div className='col-span-1 bg-[#F5E3CA] p-4 rounded-2xl object-shadow flex flex-col justify-center text-center'>
            <h3 className='font-extrabold text-[#C2763F] text-3xl md:text-4xl pb-4 '>Our Goal</h3>
            <h2 className='kg-red-hands font-semibold text-black text-3xl md:text-5xl leading-relaxed'>Master Your Accent and Linguistics Skills</h2>
            <br />
            <h2 className='kg-red-hands font-semibold text-black text-3xl md:text-5xl pt-1 leading-loose'>Elevate Your English with Expert Guidance</h2>
          </div>
          <div className='col-span-1 bg-[#F5E3CA] p-5 rounded-2xl kg-red-hands object-shadow flex justify-center text-center'>
            <p className=' text-lg md:text-xl text-black leading-loose '>
              At <span className='text-3xl md:text-4xl  font-extrabold'>A Voice from Egypt </span> <br />
             <span className='text-[#C2763F] font-extrabold'>Master English Pronunciation & Accent</span>  <br />
              <ul className='list-disc text-left text-lg md:text-xl font-medium pl-6 py-4'>
                  <li className='p-2'>Improve fluency and confidence</li>
                  <li className='p-2'>Learn correct sound production and articulation</li>
                  <li className='p-2'>Develop self-study skills</li>
                  <li className='p-2'>Achieve accurate pronunciation and clear American accent</li>
              </ul>
              <span className='text-[#C2763F] font-extrabold'>For English Language Majors</span> <br />
              <ul className='list-disc text-left text-lg md:text-xl font-medium pl-6 py-4'>
                  <li className='p-2'>Specialized support in core areas of linguistics</li>
                  <li className='p-2'>Overcome challenges in sociolinguistics, syntax, morphology, psycholinguistics, applied linguistics, phonology, and semantics</li>
              </ul>
              <br /> 
              <span className='text-[#C2763F] font-extrabold'>Join us today!</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
